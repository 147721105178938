import React from "react"
import styles from "./treatment.module.scss"
import BackgroundImage from "gatsby-background-image"

const treatment = props => {
  const id = props.id

  const img = (
    <BackgroundImage fluid={props.img}  Tag="div" className={styles.img}>
    </BackgroundImage>
  )
  
  const classes = [styles.text, id%2===0? styles.blue : null].join(' ');
  return (
    <div className={styles.treatment}>
      {id % 2 === 0 ? img : null}
      <div className={classes}>
        <h1 className={styles.header}  data-aos='fade-up'>{props.name}</h1>
        <pre className={styles.description}  data-aos='fade-up'>{props.description}</pre>
      </div>
      {id % 2 === 1 ? img : null}
    </div>
  )
}
export default treatment
