import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import styles from "./zabiegi.module.scss"
import Treatment from "../components/Treatment/treatment"
import AOS from "aos"
import "aos/dist/aos.css"
import SEO from "../components/seo"

const Zabiegi = ({ data }) => {
  const masaz = data.treatments.edges[0].node.childImageSharp.fluid
  const cieplolecznictwo = data.treatments.edges[1].node.childImageSharp.fluid
  const kinezyterapia = data.treatments.edges[2].node.childImageSharp.fluid
  const hydroterapia = data.treatments.edges[3].node.childImageSharp.fluid
  const fizykoterapia = data.treatments.edges[4].node.childImageSharp.fluid
  useEffect(() => {
    AOS.init({
      duration: 500,
    })
  }, [])

  return (
    <Layout>
      <SEO
        title="Zabiegi"
        keywords="Fizjoterapia Legnica,
         Fizjoterapeuta Legnica, Fizjoterapeutka Legnica,
          Masaż Legnica, Masażysta Legnica, Masażystka Legnica,
           Hydroterapia Legnica, Kinezyterapia Legnica, lekarz Legnica,
            terapeuta Legnica, ból pleców Legnica, masaże Legnica,
             rehabilitacja Legnica, Anna Mysyk Legnica, Anna Mysyk Legnica Rehabilitacja,"
      />

      <section className={styles.container}>
        <h1 className={styles.mainHeader} data-aos="fade-left">
          OFERTA ZABIEGÓW
        </h1>
        {data.allStrapiZabieg.edges.map((document, index) => (
          <Treatment
            name={document.node.nazwa}
            description={document.node.opis}
            id={index}
            key={index}
            img={
              document.node.nazwa === "Hydroterapia"
                ? hydroterapia
                : document.node.nazwa === "Ciepłolecznictwo"
                ? cieplolecznictwo
                : document.node.nazwa === "Kinezyterapia"
                ? kinezyterapia
                : document.node.nazwa === "Fizykoterapia"
                ? fizykoterapia
                : masaz
            }
          />
        ))}
      </section>
    </Layout>
  )
}

export default Zabiegi

export const query = graphql`
  query zabiegi {
    treatments: allFile(filter: { relativeDirectory: { eq: "zabiegi" } }) {
      edges {
        node {
          id
          name
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    allStrapiZabieg {
      edges {
        node {
          id
          nazwa
          opis
        }
      }
    }
  }
`
